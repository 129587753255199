import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Common/Subaccount/OrderHistory/Header.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/Common/Subaccount/OrderHistory/Row.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Common/EmptyList.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Common/Table/Wrapper.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "h-full" };
const _hoisted_2 = {
  key: 0,
  class: "table"
};
import { MarketType } from "@injectivelabs/sdk-ui-ts";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  props: {
    market: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const derivativeStore = useDerivativeStore();
    const spotStore = useSpotStore();
    const isSpot = props.market.type === MarketType.Spot;
    const orderHistories = computed(
      () => isSpot ? spotStore.subaccountOrderHistory : derivativeStore.subaccountOrderHistory
    );
    const sortedOrderHistories = computed(
      () => orderHistories.value.sort((o1, o2) => o2.updatedAt - o1.updatedAt)
    );
    return (_ctx, _cache) => {
      const _component_PartialsCommonSubaccountOrderHistoryHeader = __nuxt_component_0;
      const _component_PartialsCommonSubaccountOrderHistoryRow = __nuxt_component_1;
      const _component_CommonEmptyList = __nuxt_component_2;
      const _component_CommonTableWrapper = __nuxt_component_3;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_CommonTableWrapper, null, {
          default: _withCtx(() => [
            _unref(sortedOrderHistories).length > 0 ? (_openBlock(), _createElementBlock("table", _hoisted_2, [
              _createVNode(_component_PartialsCommonSubaccountOrderHistoryHeader),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sortedOrderHistories), (order, index) => {
                  return _openBlock(), _createBlock(_component_PartialsCommonSubaccountOrderHistoryRow, {
                    key: `order-history-${index}`,
                    "is-spot": isSpot,
                    order
                  }, null, 8, ["order"]);
                }), 128))
              ])
            ])) : (_openBlock(), _createBlock(_component_CommonEmptyList, {
              key: 1,
              message: _ctx.$t("trade.emptyOrders")
            }, null, 8, ["message"]))
          ]),
          _: 1
        })
      ]);
    };
  }
});
