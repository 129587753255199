import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Common/Subaccount/TradeHistory/Mobile.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Common/EmptyList.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Common/Table/Body.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Partials/Common/Subaccount/TradeHistory/Header.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/Partials/Common/Subaccount/TradeHistory/Row.vue";
import { default as __nuxt_component_5 } from "/opt/build/repo/components/Common/Table/Wrapper.vue";
import { default as __nuxt_component_6 } from "/opt/build/repo/components/Modals/MobileTradeDetails.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "h-full" };
const _hoisted_2 = {
  key: 0,
  class: "table"
};
import { MarketType } from "@injectivelabs/sdk-ui-ts";
import { Modal } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  props: {
    market: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const derivativeStore = useDerivativeStore();
    const spotStore = useSpotStore();
    const modalStore = useModalStore();
    const isSpot = props.market.type === MarketType.Spot;
    const tradeDetails = ref(void 0);
    const trades = computed(
      () => isSpot ? spotStore.subaccountTrades : derivativeStore.subaccountTrades
    );
    const sortedTrades = computed(
      () => trades.value.sort((t1, t2) => t2.executedAt - t1.executedAt)
    );
    function handleShowTradeDetails(trade) {
      tradeDetails.value = trade;
      modalStore.openModal({ type: Modal.MobileTradeDetails });
    }
    return (_ctx, _cache) => {
      const _component_PartialsCommonSubaccountTradeHistoryMobile = __nuxt_component_0;
      const _component_CommonEmptyList = __nuxt_component_1;
      const _component_CommonTableBody = __nuxt_component_2;
      const _component_PartialsCommonSubaccountTradeHistoryHeader = __nuxt_component_3;
      const _component_PartialsCommonSubaccountTradeHistoryRow = __nuxt_component_4;
      const _component_CommonTableWrapper = __nuxt_component_5;
      const _component_ModalsMobileTradeDetails = __nuxt_component_6;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_CommonTableBody, {
          "show-empty": _unref(sortedTrades).length === 0,
          class: "sm:hidden max-h-lg"
        }, {
          empty: _withCtx(() => [
            _createVNode(_component_CommonEmptyList, {
              message: _ctx.$t("trade.emptyTrades"),
              class: "min-h-orders bg-gray-900"
            }, null, 8, ["message"])
          ]),
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sortedTrades), (trade, index) => {
              return _openBlock(), _createBlock(_component_PartialsCommonSubaccountTradeHistoryMobile, {
                key: `mobile-trade-history-${index}-${trade.orderHash}`,
                class: "col-span-1",
                trade,
                "is-spot": isSpot,
                onShowTradeDetails: handleShowTradeDetails
              }, null, 8, ["trade"]);
            }), 128))
          ]),
          _: 1
        }, 8, ["show-empty"]),
        _createVNode(_component_CommonTableWrapper, { class: "hidden sm:block" }, {
          default: _withCtx(() => [
            _unref(sortedTrades).length > 0 ? (_openBlock(), _createElementBlock("table", _hoisted_2, [
              _createVNode(_component_PartialsCommonSubaccountTradeHistoryHeader, { market: __props.market }, null, 8, ["market"]),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sortedTrades), (trade, index) => {
                  return _openBlock(), _createBlock(_component_PartialsCommonSubaccountTradeHistoryRow, {
                    key: `trades-history-${index}-${trade.orderHash}`,
                    trade,
                    "is-spot": isSpot
                  }, null, 8, ["trade"]);
                }), 128))
              ])
            ])) : (_openBlock(), _createBlock(_component_CommonEmptyList, {
              key: 1,
              message: _ctx.$t("trade.emptyTrades")
            }, null, 8, ["message"]))
          ]),
          _: 1
        }),
        _createVNode(_component_ModalsMobileTradeDetails, {
          trade: _unref(tradeDetails),
          "is-spot": isSpot
        }, null, 8, ["trade"])
      ]);
    };
  }
});
