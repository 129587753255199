"use strict";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "h-8 text-left pl-3" };
const _hoisted_2 = { class: "flex items-center justify-start" };
const _hoisted_3 = { class: "h-8 text-left" };
const _hoisted_4 = { class: "h-8 text-left" };
const _hoisted_5 = { class: "h-8 text-left" };
const _hoisted_6 = { class: "h-8 text-right" };
const _hoisted_7 = { class: "flex items-center justify-end" };
const _hoisted_8 = { class: "h-8 text-right" };
const _hoisted_9 = { class: "h-8 text-right" };
const _hoisted_10 = { class: "h-8 text-right pr-3" };
export function render(_ctx, _cache) {
  const _component_CommonInfoTooltip = _resolveComponent("CommonInfoTooltip");
  return _openBlock(), _createElementBlock("thead", null, [
    _createElementVNode("tr", null, [
      _createElementVNode("th", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("trade.time")), 1),
          _createVNode(_component_CommonInfoTooltip, {
            class: "ml-2",
            tooltip: _ctx.$t("trade.timeTooltip", { type: "order" })
          }, null, 8, ["tooltip"])
        ])
      ]),
      _createElementVNode("th", _hoisted_3, _toDisplayString(_ctx.$t("trade.market")), 1),
      _createElementVNode("th", _hoisted_4, _toDisplayString(_ctx.$t("trade.type")), 1),
      _createElementVNode("th", _hoisted_5, _toDisplayString(_ctx.$t("trade.side")), 1),
      _createElementVNode("th", _hoisted_6, _toDisplayString(_ctx.$t("trade.price")), 1),
      _createElementVNode("th", null, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("trade.amount")), 1),
          _createVNode(_component_CommonInfoTooltip, {
            class: "ml-2",
            tooltip: _ctx.$t("trade.amount_tooltip")
          }, null, 8, ["tooltip"])
        ])
      ]),
      _createElementVNode("th", _hoisted_8, _toDisplayString(_ctx.$t("trade.total")), 1),
      _createElementVNode("th", _hoisted_9, _toDisplayString(_ctx.$t("trade.triggerCondition")), 1),
      _createElementVNode("th", _hoisted_10, _toDisplayString(_ctx.$t("trade.status")), 1)
    ])
  ]);
}
