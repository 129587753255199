import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Common/Subaccount/Order/Mobile.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Common/EmptyList.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Common/Table/Body.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Partials/Common/Subaccount/Order/Header.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/Partials/Common/Subaccount/Order/Row.vue";
import { default as __nuxt_component_5 } from "/opt/build/repo/components/Common/Table/Wrapper.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, mergeProps as _mergeProps, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "h-full"
};
const _hoisted_2 = {
  key: 0,
  class: "table"
};
import { MarketType } from "@injectivelabs/sdk-ui-ts";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  props: {
    market: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const spotStore = useSpotStore();
    const derivativeStore = useDerivativeStore();
    const isSpot = props.market.type === MarketType.Spot;
    const store = computed(() => isSpot ? spotStore : derivativeStore);
    const sortedOrders = computed(
      () => store.value.subaccountOrders.sort((o1, o2) => o2.updatedAt - o1.updatedAt)
    );
    return (_ctx, _cache) => {
      const _component_PartialsCommonSubaccountOrderMobile = __nuxt_component_0;
      const _component_CommonEmptyList = __nuxt_component_1;
      const _component_CommonTableBody = __nuxt_component_2;
      const _component_PartialsCommonSubaccountOrderHeader = __nuxt_component_3;
      const _component_PartialsCommonSubaccountOrderRow = __nuxt_component_4;
      const _component_CommonTableWrapper = __nuxt_component_5;
      return __props.market ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_CommonTableBody, {
          "show-empty": _unref(sortedOrders).length === 0,
          class: "sm:hidden max-h-lg"
        }, {
          empty: _withCtx(() => [
            _createVNode(_component_CommonEmptyList, {
              message: _ctx.$t("trade.emptyOrders"),
              class: "min-h-orders bg-gray-900"
            }, null, 8, ["message"])
          ]),
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sortedOrders), (order, index) => {
              return _openBlock(), _createBlock(_component_PartialsCommonSubaccountOrderMobile, {
                key: `mobile-order-${index}-${order.orderHash}`,
                class: "col-span-1",
                order,
                "is-spot": isSpot
              }, null, 8, ["order"]);
            }), 128))
          ]),
          _: 1
        }, 8, ["show-empty"]),
        _createVNode(_component_CommonTableWrapper, { class: "hidden sm:block" }, {
          default: _withCtx(() => [
            _unref(sortedOrders).length > 0 ? (_openBlock(), _createElementBlock("table", _hoisted_2, [
              _createVNode(_component_PartialsCommonSubaccountOrderHeader),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sortedOrders), (order, index) => {
                  return _openBlock(), _createBlock(_component_PartialsCommonSubaccountOrderRow, _mergeProps({
                    key: `orders-${index}-${order.orderHash}`
                  }, { isSpot, order }, { order }), null, 16, ["order"]);
                }), 128))
              ])
            ])) : (_openBlock(), _createBlock(_component_CommonEmptyList, {
              key: 1,
              message: _ctx.$t("trade.emptyOrders")
            }, null, 8, ["message"]))
          ]),
          _: 1
        })
      ])) : _createCommentVNode("", true);
    };
  }
});
