import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/TokenIcon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/App/Number/Index.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock, Fragment as _Fragment } from "vue";
const _hoisted_1 = ["data-cy", "data-cy-hash"];
const _hoisted_2 = { class: "h-12 text-left pl-3" };
const _hoisted_3 = { class: "text-white text-xs" };
const _hoisted_4 = { class: "h-12 text-left cursor-pointer" };
const _hoisted_5 = { key: 0 };
const _hoisted_6 = { class: "ml-3" };
const _hoisted_7 = {
  class: "text-gray-200 text-xs",
  "data-cy": "order-ticker-name-table-data"
};
const _hoisted_8 = { class: "h-12 text-left" };
const _hoisted_9 = { class: "text-white text-xs" };
const _hoisted_10 = { class: "h-12 text-left" };
const _hoisted_11 = {
  key: 0,
  class: "ml-1 text-xs text-gray-500",
  "data-cy": "derivative-order-reduce-only-table-data"
};
const _hoisted_12 = { class: "h-12 font-mono text-right" };
const _hoisted_13 = {
  key: 0,
  class: "text-white text-xs"
};
const _hoisted_14 = { class: "h-12 text-right font-mono" };
const _hoisted_15 = { class: "h-12 font-right text-right" };
const _hoisted_16 = { class: "text-xs text-gray-500" };
const _hoisted_17 = { class: "h-12 flex items-center justify-end gap-1" };
const _hoisted_18 = { class: "text-gray-500 text-xs font-semibold" };
const _hoisted_19 = {
  key: 0,
  class: "text-white text-xs font-semibold"
};
const _hoisted_20 = {
  key: 1,
  class: "text-white text-xs font-semibold"
};
const _hoisted_21 = { key: 1 };
const _hoisted_22 = { class: "h-12 relative text-right pr-3" };
const _hoisted_23 = { class: "text-white text-xs" };
import { getMarketRoute } from "@/app/utils/market";
export default /* @__PURE__ */ _defineComponent({
  __name: "Row",
  props: {
    isSpot: Boolean,
    order: {
      required: true,
      type: Object
    }
  },
  setup(__props) {
    const props = __props;
    const {
      type,
      isBuy,
      total,
      price,
      market,
      quantity,
      timestamp,
      isStopLoss,
      orderStatus,
      isTakeProfit,
      triggerPrice,
      isReduceOnly,
      priceDecimals,
      isMarketOrder,
      quantityDecimals
    } = useOrderHistory(
      computed(() => props.order),
      computed(() => props.isSpot)
    );
    const marketRoute = computed(() => {
      if (!market.value) {
        return void 0;
      }
      return getMarketRoute(market.value);
    });
    return (_ctx, _cache) => {
      const _component_CommonTokenIcon = __nuxt_component_0;
      const _component_NuxtLink = __nuxt_component_1;
      const _component_AppNumber = __nuxt_component_2;
      return _unref(market) ? (_openBlock(), _createElementBlock("tr", {
        key: 0,
        "data-cy": "order-table-row-" + _unref(market).ticker,
        "data-cy-hash": __props.order.orderHash
      }, [
        _createElementVNode("td", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(timestamp)), 1)
        ]),
        _createElementVNode("td", _hoisted_4, [
          _createVNode(_component_NuxtLink, {
            class: "flex items-center justify-start",
            to: _unref(marketRoute)
          }, {
            default: _withCtx(() => [
              _unref(market).baseToken ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_CommonTokenIcon, {
                  token: _unref(market).baseToken,
                  md: ""
                }, null, 8, ["token"])
              ])) : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(market).ticker), 1)
              ])
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _createElementVNode("td", _hoisted_8, [
          _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(type)), 1)
        ]),
        _createElementVNode("td", _hoisted_10, [
          _createElementVNode("span", {
            "data-cy": "order-order-side-table-data",
            class: _normalizeClass(["text-xs", {
              "text-green-500": _unref(isBuy),
              "text-red-500": !_unref(isBuy)
            }])
          }, _toDisplayString(_unref(isBuy) ? _ctx.$t("trade.buy") : _ctx.$t("trade.sell")), 3),
          _unref(isReduceOnly) ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$t("trade.reduce_only")), 1)) : _createCommentVNode("", true)
        ]),
        _createElementVNode("td", _hoisted_12, [
          _unref(isMarketOrder) ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.$t("trade.market")), 1)) : (_openBlock(), _createBlock(_component_AppNumber, {
            key: 1,
            xs: "",
            "data-cy": "order-price-table-data",
            decimals: _unref(priceDecimals),
            number: _unref(price)
          }, null, 8, ["decimals", "number"]))
        ]),
        _createElementVNode("td", _hoisted_14, [
          _createVNode(_component_AppNumber, {
            xs: "",
            "data-cy": "order-quantity-table-data",
            decimals: _unref(quantityDecimals),
            number: _unref(quantity)
          }, null, 8, ["decimals", "number"])
        ]),
        _createElementVNode("td", _hoisted_15, [
          _createVNode(_component_AppNumber, {
            xs: "",
            "data-cy": "order-total-table-data",
            decimals: _unref(market).priceDecimals,
            number: _unref(total)
          }, {
            addon: _withCtx(() => [
              _createElementVNode("span", _hoisted_16, _toDisplayString(_unref(market).quoteToken.symbol), 1)
            ]),
            _: 1
          }, 8, ["decimals", "number"])
        ]),
        _createElementVNode("td", _hoisted_17, [
          __props.order.isConditional ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t("trade.mark_price")), 1),
            _unref(isStopLoss) && !_unref(isBuy) || _unref(isTakeProfit) && _unref(isBuy) ? (_openBlock(), _createElementBlock("span", _hoisted_19, " \u2264 ")) : (_openBlock(), _createElementBlock("span", _hoisted_20, " \u2265 ")),
            _createVNode(_component_AppNumber, {
              xs: "",
              "data-cy": "order-total-table-data",
              decimals: _unref(market).priceDecimals,
              number: _unref(triggerPrice)
            }, null, 8, ["decimals", "number"])
          ], 64)) : (_openBlock(), _createElementBlock("span", _hoisted_21, "\u2014"))
        ]),
        _createElementVNode("td", _hoisted_22, [
          _createElementVNode("span", _hoisted_23, _toDisplayString(_unref(orderStatus)), 1)
        ])
      ], 8, _hoisted_1)) : _createCommentVNode("", true);
    };
  }
});
